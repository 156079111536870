import { configureStore } from '@reduxjs/toolkit';
import { serviceMiddlewares, serviceReducers } from 'services';
import authSlice from './slices/authSlice';
import bankingSlice from './slices/bankingSlice';
import salesSuppliersAccountSlice from './slices/salesSuppliersAccountSlice';
import userFilesSlice from './slices/userFilesSlice';
import customerSlice from './slices/customerSlice';
import formDataSlice from './slices/formData';

const store = configureStore({
  reducer: {
    auth: authSlice,
    banking: bankingSlice,
    salesSuppliersAccounts: salesSuppliersAccountSlice,
    userFiles: userFilesSlice,
    customer: customerSlice,
    formData: formDataSlice,
    ...serviceReducers,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(serviceMiddlewares),
});

export default store;
