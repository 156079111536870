import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { largeTextSlice } from 'utilities/helpers';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { useLocation, useMatch } from 'react-router-dom';
import ConfirmationModal from 'containers/common/components/ConfirmationModal';
import { menuItemStyles, sidebarItemStyles } from 'styles/mui/containers/layoutStyles';
import useBlocker from 'app/routes/useBlocker';
import { useSelector } from 'react-redux';

function SidebarNested({ title, icon, data, isOpen, path }) {
  const [open, setOpen] = useState(null);
  const [hasAnyValue, setHasAnyValue] = useState(false);
  const [nevigationURL, setNavigationURL] = useState(null);
  const { formValues } = useSelector(state => state.formData || {});
  const [isShowPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setHasAnyValue(
      formValues &&
        Object.values(formValues).some(value => value !== null && value !== '')
    );
  }, [formValues]);
  const blockCondition = hasAnyValue || false;
  const { handleNavigate, confirmNavigation, cancelNavigation } =
    useBlocker(setShowPopup);
  const handlePopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const handleClick = nev => {
    if (nev && blockCondition) {
      setNavigationURL(nev);
      handlePopup();
    } else {
      handleNavigate(nev, blockCondition);
    }
  };
  const { pathname } = useLocation();
  const primaryColor = useGetThemeColor();
  const isActive = pathname.includes(path);
  const handleMenuClick = event => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      {isShowPopup && (
        <ConfirmationModal
          isOpen={!!isShowPopup}
          onConfirm={() => confirmNavigation(nevigationURL)}
          onCancel={cancelNavigation}
          message="The added details will be lost. Do you wish to continue?"
        />
      )}
      <ListItemButton
        onClick={handleMenuClick}
        className={`${isOpen ? '' : 'px-4'} menu-item`}
        selected={!!isActive || open}
        sx={{
          ...sidebarItemStyles,
          '&.Mui-selected, &.Mui-selected:hover, &:hover': {
            backgroundColor: primaryColor,
          },
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        {!isOpen && (
          <>
            <ListItemText primary={largeTextSlice(title, 8)} />
            <KeyboardArrowRight />
          </>
        )}
      </ListItemButton>
      <Menu
        key={open}
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        sx={{ left: isOpen ? '3rem' : '11.5rem', top: 20 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box>
          {data &&
            data?.map(link => {
              const match = useMatch(link.path);

              let active;
              if (link.path !== '/') {
                active = link.path !== '/' && pathname.includes(link.path);
              } else if (link.path === '/') {
                active = match;
              }
              const ismenu = true;
              return (
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    handleClick(link.path);
                  }}
                  className={`${ismenu ? '' : 'px-4'}`}
                  selected={!!active}
                  sx={{
                    ...(ismenu
                      ? menuItemStyles
                      : {
                        ...sidebarItemStyles,
                        '&.Mui-selected, &:hover': { backgroundColor: primaryColor },
                      }),
                    '&.Mui-selected, &.Mui-selected:hover, &:hover': {
                      backgroundColor: primaryColor,
                    },
                  }}
                >
                  {ismenu && <ListItemText primary={`${link.title}`} />}
                </ListItemButton>
              );
            })}
        </Box>
      </Menu>
    </>
  );
}

SidebarNested.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  data: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

SidebarNested.defaultProps = {
  title: 'Link',
  icon: null,
  data: [],
};

export default SidebarNested;
