import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip, IconButton, Menu, MenuItem, Typography, Box } from '@mui/material';
import { InfoOutlined, Notifications, NotificationsOutlined } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { useGetAnnouncementsListQuery } from 'services/private/announcements';
import useAuthInfo from 'customHooks/useAuthInfo';
import ConfirmationModal from 'containers/common/components/ConfirmationModal';
import { useSelector } from 'react-redux';
import useBlocker from 'app/routes/useBlocker';

function AnnouncmentDropdown() {
  const { company } = useAuthInfo();
  const [url, setURL] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [hasAnyValue, setHasAnyValue] = useState(false);

  const { formValues } = useSelector(state => state.formData || {});
  const [isShowPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setHasAnyValue(
      formValues &&
        Object.values(formValues).some(value => value !== null && value !== '')
    );
  }, [formValues]);

  const blockCondition = hasAnyValue || false;
  const { handleNavigate, confirmNavigation, cancelNavigation } =
    useBlocker(setShowPopup);
  const handlePopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const handleClick = path => {
    if (path && blockCondition) {
      handlePopup();
    } else {
      handleNavigate(path, blockCondition);
    }
  };
  const primaryColor = useGetThemeColor();
  const { data: announcements } = useGetAnnouncementsListQuery({
    company: company?.id,
  });

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectAnnouncement = ann => {
    setURL(`/hr/announcements/${ann.id}/detail`);
    handleClick(`/hr/announcements/${ann.id}/detail`);
  };

  return (
    <>
      {isShowPopup && (
        <ConfirmationModal
          isOpen={!!isShowPopup}
          onConfirm={() => confirmNavigation(url)}
          onCancel={cancelNavigation}
          message="The added details will be lost. Do you wish to continue?"
        />
      )}
      <Tooltip title="Announcement" placement="bottom">
        <IconButton onClick={handleMenuClick} sx={{ maxHeight: '40px' }}>
          <InfoOutlined />
        </IconButton>
      </Tooltip>

      <Menu
        key={isOpen}
        open={!!isOpen}
        anchorEl={isOpen}
        onClose={handleClose}
        sx={{
          top: 42,
          left: '-12rem',
          height: '300px',
          overflow: 'auto',
          '& .Mui-selected, & li:hover': {
            backgroundColor: `${primaryColor} !important`,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {announcements?.results?.map(val => (
          <MenuItem
            key={val.id}
            // selected={profiles.id === company?.id}
            sx={{
              backgroundColor:
                new Date(val.end_date) > new Date() ? '#8080801c' : 'transparent',
            }}
            onClick={() => {
              handleClose();
              handleSelectAnnouncement(val);
            }}
          >
            <Box
              sx={{
                minWidth: '300px',
              }}
              className="d-flex justify-content-between"
            >
              <Typography variant="body2">{val?.subject?.length > 30
                ? `${val.subject.substring(0, 30)}...`
                : val.subject}
              </Typography>
              {new Date(val.end_date) > new Date() ? (
                <Notifications color={primaryColor} />
              ) : (
                <NotificationsOutlined />
              )}
            </Box>
          </MenuItem>
        ))}
        {announcements?.results?.length === 0 && (
          <MenuItem>
            <Typography variant="body2">No Announcements Found</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default AnnouncmentDropdown;
