import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { Modal, Box, IconButton, Card, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { removeFormData } from 'store/slices/formData';
// import { useLocation } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';

function CommonModal({
  isOpen,
  toggle,
  children,
  title,
  func,
  btnName,
  isPopup,
  givenWidth,
  noCloseSection,
}) {
  const dispatch = useDispatch();
  const [isConfirmOpen, setConfirmModal] = React.useState(false);
  const [hasAnyValue, setHasAnyValue] = useState(false);
  // const { pathname } = useLocation();
  const { formValues } = useSelector(state => state.formData || {});

  useEffect(() => {
    setHasAnyValue(
      formValues &&
        Object.values(formValues).some(value => value !== null && value !== '')
    );
  }, [formValues]);

  const blockCondition =
    (hasAnyValue || false);

  const handleToggle = () => {
    if (blockCondition) {
      setConfirmModal(true);
    } else {
      toggle();
    }
  };
  const handleConfirm = () => {
    setConfirmModal(false);
    dispatch(removeFormData(null));
    toggle();
  };
  const handleCancel = () => {
    setConfirmModal(false);
  };
  return (
    <>
      <Modal
        // disablePortal
        // disableScrollLock
        open={isOpen}
        onClose={handleToggle}
        className={`d-flex justify-content-center ${
          isPopup ? 'mt-4 align-items-start' : 'align-items-center'
        } modal-scroll`}
      >
        <Card
          sx={{ padding: '5px 15px', minWidth: isPopup && givenWidth, maxWidth: '850px' }}
        >
          {!noCloseSection && (
            <Box
              className="d-flex justify-content-between align-items-center pb-2"
              sx={{
                borderBottom: !isPopup && '1px solid #ccc',
                marginBottom: !isPopup && '10px',
                padding: isPopup && '0px 10px',
              }}
            >
              <Typography variant="body1">{title}</Typography>
              <Box className="d-flex gap-2">
                {btnName && func && (
                  <Button onClick={func} variant="contained" size="small">
                    {btnName}
                  </Button>
                )}
                <IconButton onClick={handleToggle}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          )}

          <Box
            sx={{ overflowY: 'auto', maxHeight: 'calc(80vh - 48px)' }}
            className={!isPopup && 'px-2 py-1'}
          >
            {children}
          </Box>
        </Card>
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message="The added details will be lost. Do you wish to continue?"
      />
    </>
  );
}

CommonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  func: PropTypes.func,
  btnName: PropTypes.string,
  isPopup: PropTypes.bool,
  givenWidth: PropTypes.string,
  noCloseSection: PropTypes.bool,
};

CommonModal.defaultProps = {
  func: null,
  btnName: null,
  isPopup: false,
  title: '',
  givenWidth: '300px',
  noCloseSection: false,
};

export default CommonModal;
