import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Button, useTheme, Toolbar, Card } from '@mui/material';
import { WarningAmberRounded } from '@mui/icons-material';

function ConfirmationModal({ isOpen, onConfirm, onCancel, message }) {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <Modal
      open={isOpen}
      onClose={onCancel}
      className="d-flex justify-content-center mt-4 align-items-start modal-scroll"
    >
      <Card sx={{ maxWidth: '350px', minWidth: '350px' }}>
        <Box className="d-flex justify-content-center">
          <WarningAmberRounded style={{ fontSize: '4rem', color: main }} />
        </Box>
        <Typography variant="h3" sx={{ fontSize: '22px', textAlign: 'center' }}>
          Attention!
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          {message}
        </Typography>
        <Toolbar className="justify-content-center gap-2">
          <Button variant="mutedColor" size="small" onClick={onCancel}>
            No
          </Button>
          <Button variant="contained" size="small" onClick={onConfirm}>
            Yes
          </Button>
        </Toolbar>
      </Card>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // title: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  message: 'Are you sure you want to continue?',
  // title: 'Confirmation',
};

export default ConfirmationModal;
