/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar, Typography } from '@mui/material';
import useAuthInfo from 'customHooks/useAuthInfo';
import { topbarStyling, topbarRightStyling } from 'styles/mui/containers/topbar';
import SearchPopup from 'containers/common/components/SearchPopup';
import SearchForm from 'containers/common/components/SearchForm';
import { useTheme } from '@emotion/react';
import TopbarProfile from './TopbarProfile';
import QuickCreateMenu from './QuickCreateMenu';
import RecentActivity from './RecentActivity';
import { topbarSearchInitialValues, topbarSearchModifiedValues } from '../utilities/data';
import CompanyMenu from './CompanyMenu';
import AnnouncmentDropdown from './Announcment';

function Topbar({ isOpen }) {
  const { palette } = useTheme();
  const { company, allowedModules } = useAuthInfo();
  const primary = palette.primary.main;
  const isSalesSection = allowedModules?.find(module => module?.section === 'Sales');
  const isPurchaseSection = allowedModules?.find(
    module => module?.section === 'Purchase'
  );

  return (
    <Toolbar
      sx={{
        ...topbarStyling,
        backgroundColor: 'white',
        width: isOpen ? 'calc(100% - 120px)' : 'calc(100% - 246px)',
      }}
      className="no-print d-flex justify-content-between align-items-center overflow-hidden"
    >
      <Box sx={{ maxWidth: '67%' }}>
        <Typography variant="h3" className="w-100">
          Welcome to{' '}
          <span style={{ color: primary, fontStyle: 'italic', fontWeight: 'bold' }}>
            {company?.company_name?.toUpperCase()}
          </span>
        </Typography>
      </Box>

      <Box className="d-flex align-items-center gap-2">
        {(isSalesSection || isPurchaseSection) && (
          <Box className="d-flex gap-1 justify-content-end align-items-center px-2 ml-4">
            <AnnouncmentDropdown />
            <QuickCreateMenu />
            <RecentActivity />
            <SearchPopup isTopbarSelect>
              {toggle => (
                <SearchForm
                  toggle={toggle}
                  initValues={topbarSearchInitialValues}
                  modifiedData={topbarSearchModifiedValues}
                  resetValues={topbarSearchInitialValues}
                  isTopbarSelect
                />
              )}
            </SearchPopup>
          </Box>
        )}

        <CompanyMenu />

        <Box sx={topbarRightStyling}>
          <TopbarProfile />
        </Box>
      </Box>
    </Toolbar>
  );
}

Topbar.propTypes = {
  // handleToggleSidebar: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Topbar;
