/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { ACCOUNTING_STAFF, HR_CONTROLLER, HR_DEPT, HR_STAFF } from 'utilities/constants';
import useAuthInfo from 'customHooks/useAuthInfo';
import {
  Groups,
  Category,
  AccountBalance,
  ShoppingCart,
  Person,
  Assessment,
  MenuBook,
  Savings,
  EnergySavingsLeaf,
  AdminPanelSettings,
  Diversity2,
  AccountCircle,
  Layers,
  Apartment,
  ReceiptLong,
  CardTravel,
  Settings,
  People,
  Draw,
  Restore,
  CreditCard,
  SettingsAccessibility,
  AssignmentOutlined,
  Storage,
  Delete,
  Domain,
  SupervisedUserCircle,
  Accessibility,
  ViewModule,
} from '@mui/icons-material';

import {
  DashboardItem,
  financeHrLinks,
  // SettingsItem,
  Reports,
  InternalMeetings,
  bookingModuleLinks,
  Attendance,
  DailyTask,
} from '../../utilities/data';

const useGetSidebarLinks = () => {
  const { company, allowedSections, allowedModules } = useAuthInfo();
  const isAttendanceSection = allowedModules?.find(
    module => module?.section === 'Time Trace'
  );
  const isSalesSection = allowedModules?.find(module => module?.section === 'Sales');
  const isPurchaseSection = allowedModules?.find(
    module => module?.section === 'Purchase'
  );
  const isFinanceSection = allowedModules?.find(module => module?.section === 'Finance');

  const isReportSection = allowedModules?.find(module => module?.section === 'Reports');
  const isHrSection = allowedModules?.find(module => module?.section === 'HR');
  const isCompanySection = allowedModules?.find(module => module?.section === 'Company');
  const isMiscellaneousSection = allowedModules?.find(
    module => module?.section === 'Miscellaneous'
  );
  const isSettingsSection = allowedModules?.find(
    module => module?.section === 'Settings'
  );

  const companyLinks = [
    ...(allowedSections?.company_profiles?.all || allowedSections?.company_profiles?.get
      ? [
          {
            title: 'Company Profiles',
            path: '/company/company-profiles',
          },
        ]
      : []),
    ...(allowedSections?.company_files?.all || allowedSections?.company_files?.get
      ? [
          {
            title: 'Company Files',
            path: '/company/company-files',
          },
        ]
      : []),
  ];
  const commonSidebarLinks = [
    ...(allowedSections?.activities || allowedSections?.activities?.get
      ? [
          {
            title: 'Activities',
            path: '/manage-activities',
            icon: <AssignmentOutlined />,
          },
        ]
      : []),
    ...(allowedSections?.storage || allowedSections?.storage?.get
      ? [
          {
            title: 'Storage',
            path: '/storage',
            icon: <Storage />,
          },
        ]
      : []),
    ...(allowedSections?.meetings || allowedSections?.meetings?.get
      ? [
          {
            title: 'Meetings',
            path: '/internal-meetings',
            icon: <Groups />,
          },
        ]
      : []),
    ...(allowedSections?.trash || allowedSections?.trash?.get
      ? [
          {
            title: 'Trash',
            path: '/trash',
            icon: <Delete />,
          },
        ]
      : []),
  ];
  const salesLinks = [
    ...(allowedSections?.account_master ||
    allowedSections?.account_master?.all ||
    allowedSections?.account_master?.get
      ? [
          {
            title: 'Account Master',
            path: '/accounting/sales/accounts',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.customer ||
    allowedSections?.customer?.all ||
    allowedSections?.customer?.get
      ? [
          {
            title: 'Customers',
            path: '/accounting/sales/customers',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.quotation ||
    allowedSections?.quotation?.all ||
    allowedSections?.quotation?.get
      ? [
          {
            title: 'Quotation',
            path: '/accounting/sales/quotations',
            permissions: [],
          },
        ]
      : []),

    ...(allowedSections?.proforma_invoice ||
    allowedSections?.proforma_invoice?.all ||
    allowedSections?.proforma_invoice?.get
      ? [
          {
            title: 'Proforma Invoice',
            path: '/accounting/sales/pro-invoices',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.sales_invoice ||
    allowedSections?.sales_invoice?.all ||
    allowedSections?.sales_invoice?.get
      ? [
          {
            title: 'Sales Invoice',
            path: '/accounting/sales/invoices',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.receipt_voucher ||
    allowedSections?.receipt_voucher?.all ||
    allowedSections?.receipt_voucher?.get
      ? [
          {
            title: 'Receipt Voucher',
            path: '/accounting/sales/payments-received',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.sales_credit_note ||
    allowedSections?.sales_credit_note?.all ||
    allowedSections?.sales_credit_note?.get
      ? [
          {
            title: 'Sales Credit Note',
            path: '/accounting/sales/credit-notes',
            permissions: [],
          },
        ]
      : []),
  ];

  const purchasesLinks = [
    ...(allowedSections?.supplier_master ||
    allowedSections?.supplier_master?.all ||
    allowedSections?.supplier_master?.get
      ? [
          {
            title: 'Supplier Master',
            path: '/accounting/purchases/suppliers',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.expense ||
    allowedSections?.expense?.all ||
    allowedSections?.expense?.get
      ? [
          {
            title: 'Expense',
            path: '/accounting/purchases/expenses',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.purchase_order ||
    allowedSections?.purchase_order?.all ||
    allowedSections?.purchase_order?.get
      ? [
          {
            title: 'Purchase Order',
            path: '/accounting/purchases/purchase-orders',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.purchase_invoice ||
    allowedSections?.purchase_invoice?.all ||
    allowedSections?.purchase_invoice?.get
      ? [
          {
            title: 'Purchase Invoice',
            path: '/accounting/purchases/bills',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.payment_voucher ||
    allowedSections?.payment_voucher?.all ||
    allowedSections?.payment_voucher?.get
      ? [
          {
            title: 'Payment Voucher',
            path: '/accounting/purchases/payments-made',
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.purchase_debit_note ||
    allowedSections?.purchase_debit_note?.all ||
    allowedSections?.purchase_debit_note?.get
      ? [
          {
            title: 'Purchase Debit Note',
            path: '/accounting/purchases/debit-notes',
            permissions: [],
          },
        ]
      : []),
  ];
  const accountantLinks = useMemo(
    () => [
      ...(allowedSections?.currencies ||
      allowedSections?.currencies?.all ||
      allowedSections?.currencies?.get
        ? [
            {
              title: 'Currencies',
              path: '/accounting/accountant/currencies',
              permissions: [],
            },
          ]
        : []),
      ...(allowedSections?.journal_voucher ||
      allowedSections?.journal_voucher?.all ||
      allowedSections?.journal_voucher?.get
        ? [
            {
              title: 'Journal Voucher',
              path: '/accounting/accountant/journals',
              permissions: [],
            },
          ]
        : []),

      ...(allowedSections?.tax_management ||
      allowedSections?.tax_management?.all ||
      allowedSections?.tax_management?.get
        ? [
            {
              title: 'Tax Management',
              path: '/accounting/accountant/tax-payments?active_tab=tax_dues',
              permissions: [],
            },
          ]
        : []),
      ...(allowedSections?.chart_of_account ||
      allowedSections?.chart_of_account?.all ||
      allowedSections?.chart_of_account?.get
        ? [
            {
              title: 'Chart of Account',
              path: '/accounting/accountant/chart-of-accounts',
              permissions: [],
            },
          ]
        : []),
    ],
    [allowedSections]
  );

  const hrLinks = [
    ...(allowedSections?.employees ||
    allowedSections?.employees?.all ||
    allowedSections?.employees?.get
      ? [
          {
            title: 'Employees',
            path: '/hr/employees',
          },
        ]
      : []),
    ...(allowedSections?.leaves_request ||
    allowedSections?.leaves_request?.all ||
    allowedSections?.leaves_request?.get
      ? [
          {
            title: 'Leave Requests',
            path: '/hr/leaves',
          },
        ]
      : []),
    ...(allowedSections?.salaries ||
    allowedSections?.salaries?.all ||
    allowedSections?.salaries?.get
      ? [
          {
            title: 'Salaries',
            path: '/hr/salaries',
          },
        ]
      : []),
    {
      title: 'Assets',
      path: '/hr/assets',
    },
    ...(allowedSections?.holidays ||
    allowedSections?.holidays?.all ||
    allowedSections?.holidays?.get
      ? [
          {
            title: 'Holidays',
            path: '/hr/holidays',
          },
        ]
      : []),

    ...(allowedSections?.announcements ||
    allowedSections?.announcements?.all ||
    allowedSections?.announcements?.get
      ? [
          {
            title: 'Announcements',
            path: '/hr/announcements',
          },
        ]
      : []),

    ...(allowedSections?.documents_request ||
    allowedSections?.documents_request?.all ||
    allowedSections?.documents_request?.get
      ? [
          {
            title: 'Document Requests',
            path: '/hr/employee-doc-request',
          },
        ]
      : []),
  ];
  const superUserSidebar = [
    {
      title: 'Main',
      type: 'lable',
    },
    DashboardItem,
    //     ...(isAttendanceSection
    //       ? Attendance
    // : []),
    ...(isAttendanceSection ? [Attendance] : []),
    // DailyTask,
    ...(allowedSections?.bank_master ||
    allowedSections?.services ||
    allowedSections?.inventory_adjustments ||
    isSalesSection ||
    isPurchaseSection ||
    isFinanceSection ||
    isReportSection
      ? [
          {
            type: 'divider',
          },
          ...(isHrSection
            ? [
                {
                  title: 'HR Management',
                  type: 'lable',
                },
                {
                  data: hrLinks,
                  title: 'HR',
                  icon: <Diversity2 />,
                  path: null,
                  location: '/hr',
                },
                ...(isCompanySection
                  ? [
                      {
                        data: companyLinks,
                        title: 'Company',
                        icon: <Apartment />,
                        path: null,
                        location: '/company',
                      },
                    ]
                  : []),
              ]
            : []),
          {
            type: 'divider',
          },
          {
            title: 'Accounting',
            type: 'lable',
          },
        ]
      : []),
    ...(allowedSections?.bank_master || allowedSections?.bank_master?.get
      ? [
          {
            title: 'Bank Master',
            path: '/accounting/banking',
            icon: <AccountBalance />,
            permissions: [],
          },
        ]
      : []),
    ...(allowedSections?.item_master || allowedSections?.item_master?.get
      ? [
          {
            title: 'Services',
            path: '/accounting/items',
            icon: <Category />,
            permissions: [],
          },
        ]
      : []),
    // ...((company?.is_inventory_allowed && (allowedSections?.inventory_adjustments || allowedSections?.inventory_adjustments?.get))
    //   ? [
    //       {
    //         title: 'Inventory Adjustments',
    //         path: '/accounting/inventoryAdjustments',
    //         icon: <Groups />,
    //         permissions: [],
    //       },
    //     ]
    //   : []),
    ...(isSalesSection
      ? [
          {
            data: salesLinks,
            title: 'Sales',
            path: null,
            location: '/accounting/sales',
            icon: <ReceiptLong />,
            permissions: [],
          },
        ]
      : []),
    ...(isPurchaseSection
      ? [
          {
            data: purchasesLinks,
            title: 'Purchase',
            path: null,
            location: '/accounting/purchases',

            icon: <ShoppingCart />,
            permissions: [],
          },
        ]
      : []),
    ...(isFinanceSection
      ? [
          {
            data: accountantLinks,
            title: 'Finance',
            path: null,
            location: '/accounting/accountant',
            icon: <CardTravel />,
            permissions: [],
          },
        ]
      : []),
    ...(isReportSection ? [Reports] : []),
    {
      type: 'divider',
    },
    {
      title: 'Others',
      type: 'lable',
    },
  ];

  const settingSubMenus = [
    ...(isSettingsSection
      ? [
          ...(allowedSections?.opening_balance || allowedSections?.opening_balance?.get
            ? [
                {
                  title: 'Opening Balance',
                  path: '/accounting/opening-balance',
                  permissions: [],
                  icon: <CreditCard />,
                },
              ]
            : []),
          ...(allowedSections?.stamp_and_signature ||
          allowedSections?.stamp_and_signature?.get
            ? [
                {
                  title: 'Stamp & Signature',
                  path: '/accounting/stemp-signature',
                  permissions: [],
                  icon: <Draw />,
                },
              ]
            : []),
          ...(allowedSections?.user_accessibility ||
          allowedSections?.user_accessibility?.get
            ? [
                {
                  title: 'User Accessibility',
                  path: '/accounting/accessibility',
                  permissions: [],
                  icon: <Accessibility />,
                },
              ]
            : []),
        ]
      : []),
    ...(allowedSections?.departments || allowedSections?.departments?.get
      ? [
          {
            title: 'Departments',
            path: '/accounting/department',
            permissions: [],
            icon: <Domain />,
          },
        ]
      : []),
    ...(allowedSections?.role || allowedSections?.role?.get
      ? [
          {
            title: 'Roles',
            path: '/accounting/role',
            permissions: [],
            icon: <SupervisedUserCircle />,
          },
        ]
      : []),
    ...(allowedSections?.shift || allowedSections?.shift?.get
      ? [
          {
            title: 'Shifts',
            path: '/shifts',
            permissions: [],
            icon: <Restore />,
          },
        ]
      : []),
    {
      title: 'Categories',
      path: '/categories',
      permissions: [],
      icon: <ViewModule />,
    },
  ];

  const subSidebarLinks = [...(isMiscellaneousSection ? [...commonSidebarLinks] : [])];
  const settingMenus = [
    ...(isSettingsSection
      ? [
          {
            title: 'Settings',
            icon: <Settings />,
            data: settingSubMenus,
          },
        ]
      : []),
  ];

  return {
    superUserSidebar,
    settingMenus,
    subSidebarLinks,
    isMiscellaneousSection,
    isSettingsSection,
  };
};

export default useGetSidebarLinks;
