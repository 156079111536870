import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formValues: null,
};

const formDataSlice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    getFormData: (state, action) => ({
      ...state,
      formValues: action.payload,
    }),
    removeFormData: state => ({
      ...state,
      formValues: null,
    }),
  },
});
export const { getFormData, removeFormData } = formDataSlice.actions;
export default formDataSlice.reducer;
