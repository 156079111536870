// import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { removeFormData } from 'store/slices/formData';
import { useDebouncedCallback } from 'use-debounce';

const useBlocker = setShowPopup => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirmNavigation = nev => {
    setShowPopup(false);
    dispatch(removeFormData(null)); // Clear form data on navigation
    navigate(nev);
  };
  const cancelNavigation = () => {
    setShowPopup(false);
  };
  const handleNavigate = useDebouncedCallback((nev, blockCondition) => {
    if (!blockCondition) {
      dispatch(removeFormData(null)); // Clear form data on navigation
      navigate(nev);
    }
  }, 0);

  return {
    handleNavigate,
    confirmNavigation,
    cancelNavigation,
  };
};

export default useBlocker;
