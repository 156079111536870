/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ArrowBack, Layers, Menu, Settings } from '@mui/icons-material';
import useAuthInfo from 'customHooks/useAuthInfo';
import PropTypes from 'prop-types';
import { sidebarItemStyles } from 'styles/mui/containers/layoutStyles';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import useGetSidebarLinks from './customHooks/useGetSidebarLinks';
import SidebarItem from './SidebarItem';
import SidebarNested from './SidebarNested';

function SidebarContent({ isOpen, handleToggleSidebar }) {
  const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);
  const { company } = useAuthInfo();
  const [section, setSection] = useState('');
  const {
    superUserSidebar,
    settingMenus,
    subSidebarLinks,
    isMiscellaneousSection,
    isSettingsSection,
  } = useGetSidebarLinks();

  const primaryColor = useGetThemeColor();

  const toggleSubSidebar = val => {
    setSection(val);
    setIsSubSidebarOpen(prev => !prev);
  };

  return (
    <List
      className={`sidebar ${isOpen ? 'px-3' : 'px-4'}`}
      style={{
        width: isOpen ? '90px' : '225px',
        boxShadow: '0 2px 15px 0 rgba(0,0,0,0.2)',
      }}
    >
      <Box className="d-flex gap-1 justify-content-center align-items-center">
        <Box sx={{ minWidth: '40px' }}>
          <IconButton onClick={handleToggleSidebar}>
            <Menu />
          </IconButton>
        </Box>
        {!isOpen && (
          <img src={company?.company_logo} alt={company?.company_name} width={150} />
        )}
      </Box>

      <List>
        {isSubSidebarOpen ? (
          <>
            <ListItemButton
              sx={{
                '&:hover': { backgroundColor: primaryColor },
              }}
              className="mb-2"
              onClick={() => toggleSubSidebar('')}
            >
              <ListItemIcon>
                <ArrowBack />
              </ListItemIcon>
              {!isOpen && <ListItemText primary="Back" />}
            </ListItemButton>
            {section === 'isMiscellaneous' ? (
              <>
                {' '}
                {subSidebarLinks.map((link, index) => (
                  <SidebarItem
                    type={link?.type}
                    icon={link?.icon}
                    title={link?.title}
                    path={link?.path}
                    key={`${link?.title}-${index}-${link?.path}`}
                    isOpen={isOpen}
                  />
                ))}
              </>
            ) : (
              <>
                {' '}
                {settingMenus[0]?.data?.map((link, index) => (
                  <SidebarItem
                    type={link?.type}
                    icon={link?.icon}
                    title={link?.title}
                    path={link?.path}
                    key={`${link?.title}-${index}-${link?.path}`}
                    isOpen={isOpen}
                  />
                ))}
              </>
            )}
          </>
        ) : (
          superUserSidebar.map((link, index) => {
            if (link?.data) {
              return (
                <SidebarNested
                  key={`${link?.title}-${index}`}
                  title={link?.title}
                  icon={link?.icon}
                  data={link?.data}
                  isOpen={isOpen}
                  path={link?.location}
                />
              );
            }
            return (
              <SidebarItem
                type={link?.type}
                icon={link?.icon}
                title={link?.title}
                path={link?.path}
                key={`${link?.title}-${index}-${link?.path}`}
                isOpen={isOpen}
              />
            );
          })
        )}

        {!isSubSidebarOpen && (
          <>
            {isMiscellaneousSection && (
              <ListItemButton
                onClick={() => toggleSubSidebar('isMiscellaneous')}
                sx={{
                  ...sidebarItemStyles,
                  '&.Mui-selected, &:hover': { backgroundColor: primaryColor },
                }}
                className={`${isOpen ? '' : 'px-4'} menu-item`}
              >
                <ListItemIcon>
                  <Layers />
                </ListItemIcon>
                {!isOpen && <ListItemText primary="Miscellaneous" />}
              </ListItemButton>
            )}
            {isSettingsSection && (
              <ListItemButton
                onClick={() => toggleSubSidebar('settings')}
                sx={{
                  ...sidebarItemStyles,
                  '&.Mui-selected, &:hover': { backgroundColor: primaryColor },
                }}
                className={`${isOpen ? '' : 'px-4'} menu-item`}
              >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                {!isOpen && <ListItemText primary="Settings" />}
              </ListItemButton>
            )}
          </>
        )}
      </List>
    </List>
  );
}
export default SidebarContent;
SidebarContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
};
