import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useLocation, useMatch } from 'react-router-dom';
import { menuItemStyles, sidebarItemStyles } from 'styles/mui/containers/layoutStyles';
import useGetThemeColor from 'customHooks/useGetThemeColor';
// import RouteGuard from 'customHooks/RouteGuard';
import ConfirmationModal from 'containers/common/components/ConfirmationModal';
import useBlocker from 'app/routes/useBlocker';
import { useSelector } from 'react-redux';

function SidebarItem({ title, icon, path, isOpen, ismenu, type }) {
  const [hasAnyValue, setHasAnyValue] = useState(false);
  const { pathname } = useLocation();
  const match = useMatch(path);
  const { formValues } = useSelector(state => state.formData || {});
  const [isShowPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setHasAnyValue(
      formValues &&
        Object.values(formValues).some(value => value !== null && value !== '')
    );
  }, [formValues]);

  const blockCondition = hasAnyValue || false;
  const { handleNavigate, confirmNavigation, cancelNavigation } =
    useBlocker(setShowPopup);
  const primaryColor = useGetThemeColor();

  let isActive;
  if (path !== '/') {
    isActive = path !== '/' && pathname.includes(path);
  } else if (path === '/') {
    isActive = match;
  }
  const handlePopup = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  const handleClick = () => {
    if (path && blockCondition) {
      handlePopup();
    } else {
      handleNavigate(path, blockCondition);
    }
  };
  return (
    <>
      {isShowPopup && (
        <ConfirmationModal
          isOpen={!!isShowPopup}
          onConfirm={() => confirmNavigation(path)}
          onCancel={cancelNavigation}
          message="The added details will be lost. Do you wish to continue?"
        />
      )}
      <Box mb={type === 'lable' ? 1 : 0}>
        {type === 'lable' && !isOpen && (
          <Typography fontWeight={600} color="primary">
            {title}
          </Typography>
        )}
      </Box>

      {type === '' && (
        // <Link to={path}>
        <ListItemButton
          onClick={handleClick}
          className={`${isOpen || ismenu ? '' : 'px-4'} menu-item`}
          selected={!!isActive}
          sx={{
            ...(ismenu
              ? menuItemStyles
              : {
                ...sidebarItemStyles,
                '&.Mui-selected, &:hover': { backgroundColor: primaryColor },
              }),
            '&.Mui-selected, &.Mui-selected:hover, &:hover': {
              backgroundColor: primaryColor,
            },
          }}
        >
          {!ismenu && <ListItemIcon>{icon}</ListItemIcon>}
          {!isOpen && <ListItemText primary={`${title}`} />}
          {ismenu && isOpen && <ListItemText primary={`${title}`} />}
        </ListItemButton>
        // </Link>
      )}
      <Box mt={type === 'divider' ? 1 : 0} mb={type === 'divider' ? 1 : 0}>
        {type === 'divider' && !isOpen && <Divider />}
      </Box>
    </>
  );
}

SidebarItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  path: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  ismenu: PropTypes.bool,
  type: PropTypes.string,
};

SidebarItem.defaultProps = {
  title: 'Link',
  icon: null,
  path: '/',
  ismenu: false,
  type: '',
};

export default SidebarItem;
